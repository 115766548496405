.wrapper {
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
  padding-top: 60px;
  padding-bottom: 60px;
  /* padding-right: 100px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fbfbfb;
}

svg {
  display: inline-block;
  vertical-align: top;
}

h1 {
  font-weight: 900;
  font-size: 20px;
  line-height: 1;
  margin: 6px 0 6px 10px;
  display: inline-block;
  vertical-align: top;
}

button + button {
  margin-left: 10px;
}

.control-prev{
  display: none;
}

/* custom css */
@media only screen and (max-width: 767px) {
  .MuiTabs-fixed{
    height: fit-content;
    overflow: visible !important;
  }
}

.chartjs-render-monitor{
  width: 100% !important;
}
.MuiTabs-indicator {
  background-color: #261e56 !important;
}
.MuiSvgIcon-fontSizeSmall {
  font-size: 10px !important;
  color: #261e56;
}
.MuiSvgIcon-root{
  width: 20px !important;
  height: 20px !important;
  margin-top: 10px;
}
.MuiTabScrollButton-root{ 
  height: 100% !important;
  display: none !important;
}
.MuiAppBar-colorPrimary{
  background-color: #fff !important;
}
.MuiPaper-elevation4{
  box-shadow: unset !important;
}
.MuiBox-root{
  padding: 5px !important; 
}
.MuiIconButton-colorPrimary:hover{
  background-color: unset !important;
}
.MuiIconButton-colorPrimary:focus{
  background-color: unset !important;
}
.MuiIconButton-root{
  border-radius: unset !important;
  color: #fff !important; 
}
@media only screen and (min-width: 1367px) {
  .MuiIconButton-root{
    padding: 3px !important;
  }
}
.MuiAccordionSummary-content {
  margin: unset !important;
  box-shadow: unset !important;
}
.MuiPaper-elevation1{
  box-shadow: unset !important;
  margin: 8px auto !important;
}
.MuiAccordion-root:before{
  background-color: unset !important;
}
.MuiAccordionSummary-root{
  background-color: #fbfbfb !important; 
  min-height: 40px !important;
  padding: unset !important;
  border-radius: 10px !important;
}
.MuiAccordionDetails-root{
  background-color: #fbfbfb !important;
  padding-left: 23px !important;
  padding-top: unset !important;
  padding-bottom: unset !important
}
.MuiTouchRipple-root {
  display: none !important;
}